import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { Layout, Input, Button, List, Typography } from 'antd';

const { Header, Content } = Layout;
const pb = new PocketBase('http://survaillance.ninja/api');

const App = () => {
    const [requests, setRequests] = useState([]);
    const [newRequest, setNewRequest] = useState('');

    useEffect(() => {
        pb.collection('requests').getFullList().then(data => setRequests(data));

        pb.collection('requests').subscribe('*', function (e) {
            handleRealtimeUpdate(e);
        });

        return () => {
            pb.collection('requests').unsubscribe('*');
        };
    }, []);

    const handleRealtimeUpdate = (e) => {
        switch (e.action) {
            case 'create':
                setRequests(prevRequests => [...prevRequests, e.record]);
                break;
            case 'update':
                setRequests(prevRequests => prevRequests.map(req => req.id === e.record.id ? e.record : req));
                break;
            case 'delete':
                setRequests(prevRequests => prevRequests.filter(req => req.id !== e.record.id));
                break;
            default:
                break;
        }
    };

    const addRequest = async () => {
        const newRequestData = { title: newRequest, status: 'Pending' };
        await pb.collection('requests').create(newRequestData);
        setNewRequest('');
    };

    return (
        <Layout>
            <Header>
                <Typography.Title style={{ color: 'white' }}>Movie Requests</Typography.Title>
            </Header>
            <Content style={{ padding: '20px' }}>
                <List
                    bordered
                    dataSource={requests}
                    renderItem={item => (
                        <List.Item>
                            <Typography.Text>{item.title} - {item.status}</Typography.Text>
                        </List.Item>
                    )}
                />
                <Input 
                    placeholder="Request a new movie or series"
                    value={newRequest}
                    onChange={(e) => setNewRequest(e.target.value)}
                    style={{ marginTop: '20px' }}
                />
                <Button type="primary" onClick={addRequest} style={{ marginTop: '10px' }}>
                    Add Request
                </Button>
            </Content>
        </Layout>
    );
};

export default App;
